// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controls_UcbVL{display:flex;gap:1rem;width:100%}.control_7O\\+8d{cursor:pointer;display:flex;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.control_7O\\+8d:hover{transform:scale(1.15)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "controls_UcbVL",
	"control": "control_7O+8d"
};
module.exports = ___CSS_LOADER_EXPORT___;
