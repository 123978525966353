// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_GPKHo{display:flex;flex-direction:column;overflow:hidden}.control_xyfy3{cursor:pointer;display:flex;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.control_xyfy3:hover{transform:scale(1.15)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_GPKHo",
	"control": "control_xyfy3"
};
module.exports = ___CSS_LOADER_EXPORT___;
